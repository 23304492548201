import Stripe from 'stripe';

import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import mapArtist from '~/app/lib/mapper/mapArtist';
import mapTrack from '~/app/lib/mapper/mapTrack';
import mapAlbum from '~/app/lib/mapper/mapAlbum';
import { mapUser } from '../users/lib/mapUser';
import { toQueryString } from '~/app/lib/router2';
import { mapAccount } from './lib/utils';

import songwhipApi from '../songwhipApi';
import { AccountAnalytics } from './types';
import { AlbumPayload, ArtistPayload, TrackPayload } from '../types';
export * from './lib/utils';

export interface CreateAccountApiParams {
  userId: number;
  token?: string;
}

export const createAccountApi = async ({
  userId,
  token,
}: CreateAccountApiParams) => {
  const {
    json: { data },
  } = await songwhipApi(`accounts`, {
    method: 'POST',
    token,

    body: {
      userId,
      // always reuse an existing account if the user has one already,
      // this avoids client code ever making multiple accounts which
      // can break everything
      useExisting: true,
    },
  });

  return {
    account: mapAccount(data.account),
    stripeCheckoutSessionId: data.stripeCheckoutSessionId,
  };
};

export const createAccountCheckoutSessionApi = async ({
  accountId,
  token,
  ...checkoutSessionParams
}: {
  accountId: number;
  token?: string;

  priceId: string;
  successUrl: string;
  cancelUrl: string;
  couponId?: string;
  promotionCodeId?: string;

  /**
   * Invite tokens are used to verify this user has permission to
   * claim this artistId and optionally contains stripe discount coupon
   */
  inviteToken?: string;
}) => {
  const {
    json: { data },
  } = await songwhipApi(`accounts/${accountId}/checkout-session`, {
    method: 'POST',
    body: checkoutSessionParams,
    token,
  });

  return {
    account: mapAccount(data.account),
    stripeCheckoutSessionUrl: data.stripeCheckoutSessionUrl,
  };
};

export const getAccountApi = async (accountId: number) => {
  const {
    json: {
      data: { account },
    },
  } = await songwhipApi(`accounts/${accountId}`);

  return mapAccount(account);
};

export const redeemAccountInviteApi = async ({
  inviteToken,
}: {
  inviteToken: string;
}) => {
  const {
    json: {
      data: { account, user },
    },
  } = await songwhipApi(`accounts/invites/redeem`, {
    method: 'POST',

    body: {
      inviteToken,
    },
  });

  return {
    account: mapAccount(account),
    user: mapUser(user),
  };
};

export const getAccountBillingPortalUrl = async ({
  accountId,
  returnUrl,
  token,
}: {
  accountId: number;
  returnUrl: string;
  token?: string;
}) => {
  const {
    json: {
      data: { billingPortalUrl },
    },
  } = await songwhipApi(`accounts/${accountId}/billing-portal`, {
    method: 'POST',
    token,

    body: {
      returnUrl,
    },
  });

  return {
    billingPortalUrl,
  };
};

export const getAccountPagesApi = async ({
  accountId,
}: {
  accountId: number;
}) => {
  const {
    json: {
      data: { artists, albums, tracks },
    },
  } = (await songwhipApi(`accounts/${accountId}/pages`)) as {
    json: {
      data: {
        artists?: ArtistPayload[];
        albums?: AlbumPayload[];
        tracks?: TrackPayload[];
      };
    };
  };

  return {
    artists: artists?.map(mapArtist),
    albums: albums?.map(mapAlbum),
    tracks: tracks?.map(mapTrack),
  };
};

export interface GetAccountAnalyticsApiParams {
  accountId: number;
  artistIds?: number[];
  albumIds?: number[];
  trackIds?: number[];
  days: number;
  timezone?: string;
}

export interface GetAccountAnalyticsApiPayload {
  isStale: boolean;
  data: {
    table: string;
    dateFrom: string;
    dateTo: string;
    results: AccountAnalytics;
  };
}

export const getAccountAnalyticsApi = async ({
  accountId,
  artistIds,
  albumIds,
  trackIds,
  days,
  timezone,
}: GetAccountAnalyticsApiParams) => {
  return (
    await songwhipApi(
      `accounts/${accountId}/analytics?${toQueryString(
        removeUndefinedKeys({
          // sort ids so url doesn't change for cache key consistency
          artistIds: artistIds?.sort().join(','),
          albumIds: albumIds?.sort().join(','),
          trackIds: trackIds?.sort().join(','),
          timezone,
          days,
        })
      )}`
    )
  ).json as GetAccountAnalyticsApiPayload;
};

export interface ResolveStripeObjectsApiParams {
  priceId?: string;
  couponId?: string;
  promoCode?: string;
}

export interface ResolveStripeObjectsResult {
  price?: Stripe.Price;
  coupon?: Stripe.Coupon;
  promotionCode?: Stripe.PromotionCode;
}

export const resolveStripeObjectsApi = async ({
  priceId,
  couponId,
  promoCode,
}: ResolveStripeObjectsApiParams) => {
  let url = `accounts/stripe/resolve?`;

  if (priceId) url += `priceId=${priceId}&`;
  if (couponId) url += `couponId=${couponId}`;
  if (promoCode) url += `promoCode=${promoCode}`;

  const {
    json: { data },
  } = await songwhipApi(url);

  return data as ResolveStripeObjectsResult;
};
