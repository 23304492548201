import mapArtist from '~/app/lib/mapper/mapArtist';
import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { MappedAccount, AccountPayload, AccountUser } from '../types';

export const mapAccount = ({
  id,
  name,
  users,
  subscriptionEndsAt,
  subscription,
  subscriptionStatus,
  subscriptionType,
  totalArtistCredits,
  usedArtistCredits,
  customDomains,
  artists,
  orchardBrand,
  config,
}: AccountPayload): MappedAccount => ({
  id,
  name,
  subscriptionStatus,
  subscriptionType,

  subscriptionEndsAtTimestamp: subscriptionEndsAt
    ? new Date(subscriptionEndsAt).getTime()
    : undefined,

  artists: artists?.map(mapArtist),
  totalArtistCredits,
  usedArtistCredits,
  config,

  users: users?.map((accountUser) =>
    removeUndefinedKeys<AccountUser>({
      id: accountUser.id,
      name: accountUser.name,
      email: accountUser.email,
      image: accountUser.image,
      isAccountAdmin: accountUser.role === 'admin',
      joinedAtTimestamp: new Date(accountUser.joinedAt).getTime(),
    })
  ),

  subscription: subscription && {
    status: subscription.status,
    createdAt: subscription.createdAt,
    currentCycleStartedAt: subscription.currentCycleStartedAt,
    canceledAt: subscription.canceledAt,
    currentCycleEndsAt: subscription.currentCycleEndsAt,
    items: subscription.items,
    latestInvoice: subscription.latestInvoice,
  },

  customDomains,

  orchardBrand: orchardBrand ?? undefined,
});
