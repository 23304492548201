import { useCallback } from 'react';

import { patchAlbum } from '~/app/lib/store/albums/actions/patch';
import { useTracker } from '~/app/lib/tracker/useTracker';
import { useDispatch } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';
import { useI18n } from '~/app/lib/i18n';
import { ItemTypes } from '~/types';

import {
  useAppConfirm,
  useAppToast,
} from '~/app/components/NextApp/lib/CoreUi';
import { toDraftAlbumPagePath } from './utils';

const useUnpublishPage = () => {
  const appConfirm = useAppConfirm();
  const appToast = useAppToast();
  const dispatch = useDispatch();
  const { trackEvent } = useTracker();
  const appRouter = useAppRouter();
  const { t } = useI18n();

  return useCallback(
    async ({
      albumId,
      albumName,
      pagePath,
    }: {
      albumId: number;
      albumName: string;
      pagePath: string;
    }) => {
      appConfirm({
        content: t('item.confirmUnpublishPage'),
        actionText: t('item.unpublishPageAction'),

        onConfirm: async () => {
          await dispatch(
            patchAlbum({
              albumId,

              changes: {
                isDraft: true,
              },
            })
          );

          appRouter.replace(toDraftAlbumPagePath({ pagePath }));

          appToast({
            text: t('item.events.pageUnpublished'),
          });

          trackEvent({
            type: 'unpublish-live-page',

            itemId: albumId,
            itemType: ItemTypes.ALBUM,
            itemName: albumName,
          });
        },
      });
    },
    []
  );
};

export default useUnpublishPage;
