import {
  OrchardBrands,
  FeatureFlags,
  SongwhipApiUser,
  SubscriptionTypes,
  AccountConfig,
} from '../../types';

export interface UserAccount {
  id: number;
  name: string | null;
  subscriptionType: SubscriptionTypes | null;
  subscriptionEndsAt?: number;
  config: AccountConfig | null;
}

export interface User extends Pick<SongwhipApiUser, 'role'> {
  id: number;
  name?: string;
  email: string;
  image?: string;
  isAdmin: boolean;
  isOrchardAdmin: boolean;
  accounts?: UserAccount[];
  orchardId?: string;
  orchardBrand?: OrchardBrands;
  isOrchardUser: boolean;

  config?: {
    emailOptOut?: {
      NEW_PRESAVE: boolean;
    };
  };

  featureFlags: FeatureFlags;
  intercomIdentity?: string;
}

export const mapUser = ({
  orchardBrand,
  orchardId,
  config,
  role,
  accounts,
  ...rest
}: SongwhipApiUser): User => ({
  ...rest,
  role,
  isAdmin: role === 'admin',
  isOrchardAdmin: role === 'orchardAdmin',
  config: config || undefined,
  orchardId: orchardId || undefined,
  isOrchardUser: !!orchardId,
  orchardBrand: orchardBrand || undefined,

  accounts: accounts?.map(
    ({
      id,
      subscriptionType,
      subscriptionEndsAt,
      name,
      config,
    }): UserAccount => ({
      id,
      name,
      subscriptionType,
      subscriptionEndsAt: subscriptionEndsAt
        ? new Date(subscriptionEndsAt).getTime()
        : undefined,
      config,
    })
  ),
});
