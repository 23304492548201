import React, { FC } from 'react';

import Clickable from '../Clickable';
import useSelectArtists from '~/app/lib/hooks/useSelectArtists';
import { MappedArtist } from '~/app/lib/mapper';

const ArtistNames: FC<{ artistIds: number[]; withLinks?: boolean }> = ({
  artistIds,
  withLinks = true,
}) => {
  const artists = useSelectArtists(artistIds);

  return (
    <>
      {(artists?.filter(Boolean) as MappedArtist[]).map(
        ({ name, pagePath }, index) => (
          <>
            {index > 0 ? ', ' : ''}
            <Clickable
              testId="artistName"
              key={name}
              href={withLinks ? pagePath : undefined}
              // must be 'inline' so ellipsis breaks on letter not block
              style={{ display: 'inline' }}
              isInline
              title={name}
            >
              {name}
            </Clickable>
          </>
        )
      )}
    </>
  );
};

export default ArtistNames;
